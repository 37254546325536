.lista-table .card {
    margin-bottom: 0.1rem;    
    color: #535763;    
    font-family: "Open Sans", sans-serif;
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
    border: 1 solid rgba(0,0,0,.125);

}

.cabelhaco-tabela-pedido {
    padding: 1.25rem;
}

.painel-fitro-pedido .accordion__title {
    color: black!important;
}

@media only screen and (max-width: 1280px){
    .filtroLateral .botao-fechar {
        display: block!important;
    }
}
