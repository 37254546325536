.table-trechos{
    width: 100%;
}

@media only screen and (max-width: 1280px) {
    .filtroLateral .botao-fechar {
        display: block !important;
    }
}

@media only screen and (max-width: 430px) {
    .table-responsive-sm {
        padding: 0px;
    }
}