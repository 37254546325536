/* --------------Layout Galeria de divulgação -------------- */

.titulo-principal h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 30px;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

/* .titulo-principal {
  padding-top: 32px;
} */

.animated.fadeIn{
  padding-top: 36px;
}

/* #titulo-page{
  padding-top: 32px;
} */

#btn-navegation {
  font-size: 35px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-weight: bold;
}

.titulo-principal h1 a {
  margin-right: 0px;
}

.titulo-principal h1 a i {
  font-size: 18px;
}

.brdcr.lk-ativo {
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
}

.brdcr.lk-ativo {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: rgb(0, 0, 0);
}

.brdcr {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold !important;
  font-size: 20px !important;
  color: rgb(0, 0, 0) !important;
}

.galeria-container {
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 10px;
  margin: 1px;
  /* border: 1px solid rgba(70, 129, 255, 0.356); */
}

.galeria-container .cards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
  margin: 10px;
  box-shadow: 5px 5px 5px rgba(82, 77, 77, 0.644);
  border: 1px solid rgba(0, 0, 0, 0.356);
}

.galeria-container .cards .folder {
  background-image: url('../img/promocional/promocional01.png');
  background-size: 300px 250px;
  width: 300px;
  height: 250px;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: end;
  box-shadow: 5px 5px 5px rgba(82, 77, 77, 0.644);
  border: 1px solid rgba(82, 77, 77, 0.644);
  border-radius: 10px;
  gap: 10px;
  padding: 10px;
}

.galeria-container .cards .folder .name {
  font-family: Arial, Helvetica, sans-serif;
  /* font-size: max(13px, 1vw); */
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, rgba(70, 128, 255, 1) 0%, rgba(103, 211, 255, 0.9752275910364145) 100%);
  padding: 5px;
  box-shadow: 5px 5px 5px rgba(82, 77, 77, 0.644);
  border-radius: 10px;
  margin: 10px;
}

.galeria-container .cards .folder .name:hover {
  opacity: 1;
  visibility: visible;
  color: rgb(70, 128, 255);
  background: #ffffff49;
  transform: scale(1.04);
}

.galeria-container .cards .folder:hover {
  opacity: 1;
  visibility: visible;
  transform: scale(1.000001);
  border: 5px solid #ffffff;
}

/* ---------------Galeria de Acervos SUB-PASTAS ---------- */

/*  TITULO SUB PASTAS */
.titulo-sub-folder {
  font-family: Arial, Helvetica, sans-serif;
  /* font-size: max(13px, 1.2vw); */
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, rgba(70, 128, 255, 1) 0%, rgba(103, 211, 255, 0.9752275910364145) 100%);
  padding: 5px;
  box-shadow: 5px 5px 5px rgb(82 77 77 / 64%);
  border-radius: 10px;
  margin: 10px;
}

.galeria-container .cards .name-sub-folder .titulo-sub-folder:hover {
  opacity: 1;
  visibility: visible;
  color: rgb(70, 128, 255);
  background: #ffffff49;
  transform: scale(1.04);
}

#sub-pasta-img {
  background-image: url('../img/promocional/promocional02.png');
  background-size: 300px 250px;
}

/* ---------------Galeria de Acervos------------------- */

.titulo-acervo {
  display: flex;
  align-items: center;
}

.img-grid {
  display: flex;
  justify-content: space-evenly;
  margin: 20px auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  flex-wrap: wrap;
}

.img-wrap {
  /* overflow: hidden; */
  height: 0;
  padding: 50% 0;
  position: relative;
  /* opacity: 0.8; */
}

.img-wrap img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btn.btn-success {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  color: rgb(70, 128, 255);
  background-color: #ffffff;
  border-color: rgb(70, 128, 255);
}

.btn.btn-secondary {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  color: rgb(70, 128, 255);
  background-color: rgba(70, 129, 255, 0.356);
  border-color: rgb(70, 128, 255);
}

button:hover {
  transform: scale(1.05);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: rgb(70, 128, 255);
  background-color: rgb(255, 255, 255);
  border-color: rgb(70, 128, 255);
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: rgb(255, 255, 255);
  background-color: rgba(70, 129, 255, 0.356);
  border-color: rgb(70, 128, 255) !important;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 129, 255, 0.356) !important;
}

.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 129, 255, 0.356) !important;
}

.img-wrap img:hover {
  transform: scale(1.04);
  border: 5px solid #ffffff;
}

/* modal styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  
  background: rgba(0, 0, 0, 0.1)!important;
  z-index: 1;
}

.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

/* progress bar styles */
.progress-bar {
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}